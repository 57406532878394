<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        component-top
      div(class="content-right")
        div(class="right-content-border")
          button(@click="$router.push({ name: 'TopIndex' })" class="close-button") <i class="fas fa-times"></i>

          div(class="t-c")
            h5 現在のルームバナー画像
            div(class="now-img")
              img(:src="bannerOld")
            h5 ルームバナーを変更する
            <el-form ref="roomForm">
              <el-form-item label="" prop="banner_store_path_new">
                <UploadSingleImage ref="upload" v-model="banner_store_path_new" />
              </el-form-item>
            </el-form>
            div(class="caution-t") ※比率16:9（横長）、500KB以上の際は圧縮いたします<br>（推奨サイズ：615×346ピクセル、500KB以下）
          div(class="button-gemini")
            button(@click="onSubmit()" class="pink-button") 変更する
            button(@click="onCancel()" class="pink-button") キャンセル

    component-footer-index
</template>

<style src="./Normal.scss" lang="scss" scoped>
</style>

<script>
import { getRoom, setRoom } from '@/api/room'
import UploadSingleImage from '@/components/Upload/SingleImage4'
export default {
  name: "SettingRoomBannerNormal",
  components: {
    UploadSingleImage
  },
  data() {
    return {
      bannerOld: '',
      banner_store_path_new: '',
      data: {
      },
      file:undefined,
    }
  },
  props: {
  },
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    await this.syncData();
  },
  updated() {},
  destroyed() {},
  methods: {
    async syncData() {
      const ret = await getRoom();
      this.data = ret.data;
      this.bannerOld = this.data.banner_store_path ? this.$store.state.system.config.storage_addr_list.public + this.data.banner_store_path : '';
    },
    async onSubmit() {
      if (!this.banner_store_path_new) {
        this.$alert('画像が選択されていません。画像をアップロードしてください。')
        return;
      }
      await setRoom({
        banner_store_path: this.banner_store_path_new
      });
      this.$router.push({ name: 'TopIndex' })
    },
    onCancel() {
      this.$router.push({ name: 'TopIndex' })
    },
  }
};
</script>
