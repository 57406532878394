<template>
  <div class="upload-container">
    <el-row type="" align="middle">
      <el-col v-if="uploadButtonShow">
        <el-button
          @click="imagecropperShow=true"
          class="pink-button no-padding"
        >ファイルを選択</el-button>
      </el-col>
      <el-col>
        <img v-if="src" :src="src" class="image-preview-wrapper" :width="width" :height="height">
      </el-col>
    </el-row>
    <image-cropper
      v-show="imagecropperShow"
      :key="imagecropperKey"
      :width="width"
      :height="height"
      :url="action"
      :headers="headers"
      field="file"
      :no-circle="true"
      lang-type="ja"
      @close="close"
      @crop-upload-success="cropSuccess"
    />
  </div>
</template>

<script>
  import ImageCropper from '@/components/ImageCropper'
  import { getToken } from '@/utils/auth'

  export default {
    name: 'SingleImageUpload',
    components: { ImageCropper },
    props: {
      value: {
        type: String,
        default: ''
      },
      width: {
        type: Number,
        default: 615
      },
      height: {
        type: Number,
        default: 346
      },
      apiPostString: {
        type: String,
        default: 'banners'
      },
      uploadButtonShow: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        imagecropperShow: false,
        imagecropperKey: 0,
        image: '',
        tempUrl: '',
        src: '',

        action: `${process.env.VUE_APP_SERVER_PUBLIC_URL}/${this.apiPostString}`,
        dataObj: { token: '', key: '' },
        headers: {
          ContentType: 'multipart/form-data',
          Authorization: 'Bearer ' + getToken()
        },
        error: undefined
      }
    },
    computed: {
      imageUrl() {
        return this.value
      }
    },
    watch: {
      value: {
        handler() {
          if (this.value.length > 0) {
            this.src =
              `${process.env.VUE_APP_RESOURCE_BASE_PATH}` +
              this.value +
              `?imageView2/1/w/${this.width}/h/${this.height}`
          } else {
            this.src = ''
            this.rmImage()
          }
        },
        immediate: true,
        deep: true
      }
    },
    methods: {
      rmImage() {
        this.emitInput('')
      },
      emitInput(val) {
        this.$emit('input', val)
      },
      handleImageSuccess(res) {
        this.error = undefined
        if (res.data && res.data.store_path) {
          this.emitInput(res.data.store_path)
        } else if (res.message) {
          this.error = 'PNG/JPEG/GIF/SVG/WEBP画像以外はアップロード出来ません'
        }
        // this.emitInput(this.tempUrl)
      },
      beforeUpload() {},
      cropSuccess(jsonData) {
        // console.log('-------- upload success --------')
        // console.log(jsonData.store_path)
        this.error = undefined

        this.imagecropperShow = false
        this.imagecropperKey = this.imagecropperKey + 1
        this.image = jsonData.store_path
        if (this.image) {
          this.emitInput(this.image)
        } else if (jsonData.message) {
          this.error = 'PNG/JPEG/GIF/SVG/WEBP画像以外はアップロード出来ません'
        }
      },
      close() {
        this.imagecropperShow = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/mixin.scss';
  .no-padding {
    padding: 0;
  }
  .upload-container {
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    @include clearfix;
    .image-uploader {
      width: 60%;
      float: left;
    }
    .image-preview-wrapper {
      margin: 15px 0;
      max-width: 250px;
      max-height: 150px;
    }
    .image-preview {
      position: relative;
      border: 1px dashed #d9d9d9;
      float: left;
      margin-left: 50px;
      .image-preview-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .image-preview-action {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: default;
        text-align: center;
        color: #fff;
        opacity: 0;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.3s;
        cursor: pointer;
        text-align: center;
        line-height: 200px;
        .el-icon-delete {
          font-size: 36px;
        }
      }
      &:hover {
        .image-preview-action {
          opacity: 1;
        }
      }
    }
  }
</style>
